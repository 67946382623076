export default {
  public: [
    {
      path: '',
      name: 'home',
      meta: { public: true },
      component: () => import('@/modules/base/views/home.vue'),
    },
    {
      path: 'todo',
      name: 'todo',
      meta: { public: true },
      component: () => import('@/modules/base/views/todo.vue'),
    },
    // 非liff模式登入後redirect頁面
    {
      path: 'oauth-return/line',
      name: 'line-login-return',
      meta: { public: true },
      component: () => import('@/modules/base/views/lineLoginReturn.vue'),
    },
    {
      path: 'login-via-token',
      name: 'login-via-token',
      meta: { public: true },
      component: () => import('@/modules/base/views/loginViaToken.vue'),
    },
  ],
  admin: [

  ],
}
